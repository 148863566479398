import React, {Component} from "react";
import {connect} from "react-redux";
import {ActivityIndicator} from "antd-mobile";
class News extends Component
{
    render()
    {
        return <div><ActivityIndicator animating  text="loading..."/></div>
    }
}
const mapStateToProps = ({main, home}) => {
    return {
        main, home
    };
};
const mapDispatchToProps = (dispatch) => {
    return {


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(News);