import Cookies from "js-cookie";
import _ from "../utils/_";
import {fetchCoop} from "../services/co.server";
export default {
    namespace: "co",
    state: {
        loading: false,
        news:[]
    },
    subscriptions: {
        condition:["/co"],
        setup({dispatch}) {  // eslint-disable-line

        }
    },
    effects: {
        *toCoop({payload,success,error}, {dispatch, select, history}) {
            const {code,result,msg}=yield fetchCoop(payload);
            if(code==201)
            {
                success&&success();
            }else
            {
                error&&error();
            }
        }

    },
    reducers: {

    }
}
