import React,{Component} from "react";
import {Router, Route, Link} from "react-router-dom";
import NewsList from "./NewsList";
import NewsInfo from "./NewsInfo";
export default class News extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    render()
    {
        return <div>
            <Route path={`/view/news`} exact  component={NewsList}/>
            <Route path={`/view/news/detail/:id`}  component={NewsInfo}/>
        </div>
    }
}