import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
const sagaMiddleware = createSagaMiddleware();
import {take,put,call,all,race,cps,fork,join,cancel,flush,cancelled} from 'redux-saga/effects';
const methods={take,put,call,all,race,cps,fork,join,cancel,flush,cancelled};
import { createStore ,applyMiddleware,compose} from 'redux';
import {ModelLocator} from "./models/ModelLocator";
import co from "co";
import _ from "./utils/_";
import {hashHistory} from "./router";
import {isCurrent} from "./utils/Path";

let models=ModelLocator.models;
let initModel={};
let initState=null;
class Effect
{
	static exec(effect,...arg)
	{
		let arrs=Array.apply(null,arg);
		co(effect.apply(null,arrs));
	}
}

let Reducer=(state,action)=>{
	if(!initState)
	{
		initState=_.dcopy(state);
	}
	let type=action.type;
	let pos=type.indexOf("/");
	let ns=type.substr(0,pos);
	let funcStr=type.substr(pos+1);
	models.map(({namespace,effects,reducers,dispatch,select})=>{
		if(ns==namespace)
		{
			if(effects.hasOwnProperty(funcStr))
			{
				Effect.exec(effects[funcStr],action,{
					dispatch,
					history:hashHistory,
					select,
					...methods
				});
			}
			if(reducers.hasOwnProperty(funcStr))
			{
				state[namespace]=_.dcopy(reducers[funcStr](state[namespace],action));
			}
		}
	});
	return {...state};
};
models.map(({namespace,state})=>{
	initModel[namespace]={
		...state
	}
});
const enhance=applyMiddleware(thunk,sagaMiddleware);
const store=createStore(Reducer,initModel,enhance);
const {dispatch,getState}=store;
const model_run=(pathname)=>{
	models.map((model)=>{

		let {subscriptions,namespace}=model;
		model.dispatch=model.dispatch||async function(obj,...arg)
		{
			return new Promise(function(resolve, reject){

				resolve()
			}).catch((error) => {
				console.error("dispatch error:===>",error);
			}).then(()=>{
				let pos=obj.type.indexOf("/");
				if(pos==-1)
				{
					obj.type=`${namespace}/${obj.type}`;
				}
				dispatch(obj,arg)
			})
		}
		// model.select=(filter)=>{
		//     //return getState();
		//     //return filter?getState(filter):model.state
		// };

		model.select=model.select||async function(filter)
		{
			return new Promise(function(resolve, reject){
				resolve(getState);

			}).catch((error) => {
				console.error("select error:===>",error);
			}).then((data)=>{
				// console.log("ggg",data)
				return co(data);
			})
		};
		const doSetup=()=>{
			subscriptions.setup&&subscriptions.setup({
				dispatch:model.dispatch,
				history:hashHistory,
				select:model.select,
				...methods

			});
		};
		if(subscriptions)
		{
			const {condition,conditionStrict,excludes}=subscriptions;//condition满足条件则执行setup
			let jump=false;
			if(pathname&&excludes&&excludes.length)//exclude满足条件则不执行setup,否则执行
			{
				excludes.map((item)=>{
					if(isCurrent(item,pathname,!!conditionStrict))
					{
						jump=true;
					}
				})
			}
			if(jump)
			{
				return;
			}
			if(pathname&&condition&&condition.length)
			{
				condition.map((item)=>{
					if(isCurrent(item,pathname,!!conditionStrict))
					{
						doSetup()
					}
				})

			}else
			{
				doSetup();
			}


		}




	});
}
(()=>{
	model_run(hashHistory.location.pathname);
	hashHistory.listen(({pathname})=>{
		model_run(pathname);
	});
})();

export default store;
