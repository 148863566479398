import _ from "./_";
import url from "url";
/**
 * 判断是否是当前页面
 * @param isStrict-是否严格模式检测
 *
 * */
export const isCurrent=(name,pathname,isStrict)=>{
    if(name=="/")
    {
        if(pathname=="//"||pathname=="/")return true;
    }
    const selfReg=new RegExp(`^\/${name}${isStrict?"$":""}`);
    return selfReg.test(pathname);
};
/**
 * 判断是否是详情页
 * */
export const isDetail=(pathname)=>{
    const reg = /\/detail/;
    return reg.test(pathname);
};
/**
 *
 * 获取详情页有效path
 * */
export const getPath=(pathname)=>{
  const reg = /\/detail/;
  if(isDetail(pathname))
  {
      return String(pathname).replace(reg,"");
  }else
  {
      return pathname;
  }

};
/**
 *
 * get breads
 * */
export const getBreads=(menu,pathname)=>{
  let menus=JSON.parse(JSON.stringify(menu));
  function toMap(ms,parent)
  {
     return ms.map((itemData)=>{
       itemData.breads=itemData.parents||[
         {id:itemData.id,router:itemData.router,i18n:itemData.i18n,key:itemData.id}
       ];
       if(parent)
       {
          itemData.breads.push(parent);
       }
        if(itemData.children)
        {
          itemData.children=toMap(itemData.children,{id:itemData.id,router:itemData.router,i18n:itemData.i18n});
        }
       return itemData;
     });
  }
  menus=toMap(menus);
  // let isDetai=isDetail(pathname);//是否是详情页
  let path=getPath(pathname);//当前path
  let finder=null;
  function find(datas)
  {
    return _.map(datas,function(itemData){
      if(itemData.children)
      {
        return find(itemData.children);
      }
      if(itemData.router==path)
      {
        finder=itemData;
      }
      return itemData;
    });
  }
 find(menus);
  let result=finder&&finder.breads||[];
  return result.reverse().map((itemData,key)=>{
      itemData.key=key;
      itemData.path=itemData.router;
      return itemData;
  });
};
/**
 * 解析search为对象
 * @param location --location对象，history.location或者this.props.location
 * */
export const decode=(location)=> {
  const {search}=location;
  let path=url.parse(search,true,true);
  let query=path.query;
  return _.mapObject(query,(val,key)=>{
    return decodeURIComponent(val);
  });
};
/**
 * 封装search为url
 * @param pathname --路径地址
 * @param record --记录指
 * */
export const encode=(pathname,record)=>{

  return url.format({
    pathname,
    query:_.mapObject(record,(val,key)=>{
      return encodeURIComponent(val);
    })
  })
};
export const isBench=(history)=>{
    return history.location.pathname=="/workbench";
};
export const isMonitor=(history)=>{
    return history.location.pathname=="/monitor";
};