// import fetch from 'dva/fetch';
// import config from "config";
// import {Decodeuint8arr} from "./Stream";
// function parseJSON(response) {
//   return response.json();
// }
// function noJSON(response)
// {
//     return response;
// }
// function checkStatus(response) {
//     // console.log("response:",response.ok,response);
//   if (response.status >= 200 && response.status < 300) {
//     return response;
//   }
//
//   const error = new Error(response.statusText);
//   error.response = response;
//   throw error;
// }
// function readStream(response)
// {
//     console.log("stream");
//     var pump = function(reader) {
//         return reader.read().then(function(result) {
//             // if we're done reading the stream, return
//             if (result.done) {
//                 console.log("ok");
//                 return;
//             }
//             // setInterval(function(){
//             //    console.log(result.value.byteLength);
//             // },50);
//             // retrieve the multi-byte chunk of data
//             var chunk = result.value;
//             console.log(Decodeuint8arr(chunk));
//             // report our current progress
//             // progress += chunk.byteLength;
//             // console.log(((progress) * 100) + '%');
//
//             // go to next chunk via recursion
//             return pump(reader);
//         });
//     };
//
//     // start reading the response stream
//     return pump(response.body.getReader());
// }
// /**
//  * Requests a URL, returning a promise.
//  *
//  * @param  {string} url       The URL we want to request
//  * @param  {object} [options] The options we want to pass to "fetch"
//  * @return {object}           An object containing either "data" or "err"
//  */
// export default function request(url, options) {
//
//
//         //add header
//         const headers={};
//         options=options||{};
//         options.headers=options.headers||{};
//         options.headers={...options.headers,...headers};
//         if(!config.isDebug)
//         {
//             //options.headers["Authorization"]="Basic "+(new Buffer('portal:password')).toString("base64");
//             //options.headers["sigmon-method"]=options.method?String(options.method).toUpperCase():"GET";
//             options.credentials="include";
//         }
//
//   let oldFetch=fetch;
//   let newFetch=function(input, opts){//定义新的fetch方法，封装原有的fetch方法
//       return new Promise(function(resolve, reject){
//           var timeoutId =opts.hasOwnProperty("timeout")? setTimeout(function(){
//               reject(new Error("fetch timeout"))
//           }, opts.timeout):-1;
//           oldFetch(input, opts).then(
//               res=>{
//                   clearTimeout(timeoutId);
//                   resolve(res)
//               },
//               err=>{
//                   clearTimeout(timeoutId);
//                   reject(err)
//               }
//           )
//       })
//   };
//   return newFetch(url, options)
//     .then(options.isStream?readStream:checkStatus)
//     .then(options.noJSON?noJSON:parseJSON)
//     .then(data => ({ data }))
//     .catch(err => ({ err }));
// }


/**
 * 增加stream读取
 * */
// import ('es6-promise').polyfill();
import fetch from 'isomorphic-fetch';
import {Decodeuint8arr} from "./Stream";
import Cookies from 'js-cookie';
import _ from "../utils/_";
import {dispatchEvent} from "../utils/event";
const config={
  isDebug:false
};
function parseJSON(response) {
  if(response.response){
      let  status={
          code:response.code,
          success:response.success
      };
      let noshowMsg = response.options.noshowMsg
      return response.response.json().then(result=>{
          result=result||{};
          if(status.success){
              return {
                  code:status.code,
                  result:result
              }
          }else{
              return {
                  code:status.code,
                  msg:result.msg||"daicy.errormsg"
              }
          }

      }).catch((err)=>{
          return status
      })

  }else{
        return response.json?response.json():response;
  }

}
function noJSON(response)
{
  return response;
}
function checkStatus(response) {
  // console.log("response:",response.ok,response);
  // const reg=/^4/;
  // if(reg.test(response.status))
  // {
  //     //window.top.location.href="/auth/logout/";
  // }
  // if(response.status==405)
  // {
  //     window.top.cw.modal.show({
  //         title:"",
  //         body:"<div>？</div>",
  //         footer:"<div></div>",
  //         iconType:"question",
  //         showCloseBtn:false,
  //         showCancelBtn:false,
  //         onOk:function(modal)
  //         {
  //             modal.close();
  //             window.location.href="/auth/logout/";
  //         }
  //     });
  // }
  if (response.status >= 200 && response.status < 300) {
    return response;
  }else
  {
    return response;
  }

  // const error = new Error(response.statusText);
  // error.response = response;
  // throw error;
}

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }
}
function readStream(response,options)
{
  const reg=/^2/;
  if(reg.test(response.status)&&response.status!=200)
  {
    return checkStatus({
      success:true,
      code:response.status
    });
  }

  options.process=options.process||function(val){
    // console.log(response.status,val);
  };
  let noshowMsg = options.noshowMsg
  let msg="";
  var pump = function(reader) {
    return reader.read().then(function(result) {
      var val=Decodeuint8arr(result.value);
      if (result.done) {
        options.process(val,true);
        let success=reg.test(response.status);
        if(!success){
            let errmsg = msg&&isJSON(msg)?JSON.parse(msg).message||"daicy.errormsg":msg?msg:"daicy.errormsg";
        }
        return success?{
          code:response.status,
          result:options.noJSON?msg:isJSON(msg)?JSON.parse(msg):msg
        }:{
          code:response.status,
          msg:msg&&isJSON(msg)?JSON.parse(msg).msg||"daicy.errormsg":msg?msg:"daicy.errormsg"
        };
      }

      if(options.process)
      {
        options.process(val);
        msg+=val;
      }
      return pump(reader);
    });
  };

  // start reading the response stream
    if(response.body){
        return pump(response.body.getReader());
    }else{
        let code=response.status;
        let success = false;
        if(response.status >= 200 && response.status < 300)
        {

            success = true;
        }
        return {
            response,
            code,
            success,
            options

        }
    }
    // return pump(response.body.getReader());
}
function processStream(response,options)
{
  const reg=/^2/;
    options.process=options.process||function(val){

    };
    var pump = function(reader) {
        return reader.then(result=>{
            options.process(result);
        })
    };
    return pump(response.text())
}
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {

  //add header
    //url=_.param({_:Math.random()},url);

    var env = process.env.BABEL_ENV || process.env.NODE_ENV;
    var isEnvDevelopment = env === 'development';
    if(!isEnvDevelopment)
    {
        url="https://qeao.com"+url;
    }
  const headers={};
  options=options||{};
  options.headers=options.headers||{};
  options.headers={...options.headers,...headers};
  console.log("ddd",document.cookie);
   options.headers={...options.headers,"Qeao-Token":Cookies.get("Qeao-Token")};


  if(!config.isDebug)
  {
    //options.headers["Authorization"]="Basic "+(new Buffer('portal:password')).toString("base64");
    //options.headers["sigmon-method"]=options.method?String(options.method).toUpperCase():"GET";
    options.credentials="include";
       // options.credentials="omit";
 }
  let oldFetch=fetch;
  let newFetch=function(input, opts){//定义新的fetch方法，封装原有的fetch方法
    return new Promise(function(resolve, reject){
      // var timeoutId =opts.hasOwnProperty("timeout")? setTimeout(function(){
      //     reject(new Error("fetch timeout"))
      // }, opts.timeout):-1;
      oldFetch(input, opts).then(
        res=>{
          //clearTimeout(timeoutId);
          //   console.log(resolve,res);
            resolve(res);
        },
        err=>{
          //clearTimeout(timeoutId);
          reject(err)
        }
      )
    })
  };
  return newFetch(url, options)
    .then(options.ignore?function(response){
      return checkStatus(response);
    }:(options.isStream?function(response){

        if(response.body){
            return readStream(response,options)
        }else{
            return processStream(response,options)
        }


    }:function(
        response
    ){
        return readStream(response,options)
    }))
    .then((options.noJSON||options.ignore)?noJSON:parseJSON)
    .then((data) => {
        let newData=data||{};
        if(newData.code==403)
        {
            dispatchEvent("forbidden");
        }
        return (options.noJSON||options.ignore)?data:{
            ...data
        }
    })
    .catch((err) => {
        return {...err}
    });
}
