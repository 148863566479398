import React,{Component} from "react";
import ToCoop from "../../components/ToCoop";
import {connect} from "react-redux";
import PropTypes from "prop-types";
class Co extends Component
{
    constructor(props,context) {
        super(props,context);

    }
    render()
    {
        return <ToCoop
            onBack={()=>{
                this.props.history.push("/")
            }}
            onSubmit={(data)=>{
            this.props.toCoop(data,()=>{
                this.props.history.push("/co/ok");
            },()=>{
                this.props.history.push("/co/fail");
            });
        }
        }/>
    }


}
const mapStateToProps = () => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toCoop:(payload,success,error)=>{
            dispatch({
                type:"co/toCoop",
                payload,
                success,
                error
            })
        }

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Co);