import React,{Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getImage} from "../../utils/Img";
import {Link} from "react-router-dom";
import subtitleIcon from "../../theme/images/subtitle.svg";
import date from "../../theme/images/date.svg";
import _ from "../../utils/_";
import {List,WhiteSpace} from "antd-mobile";
class NewsList extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    render()
    {
        return  <div className="news_list">
            <h2>
                新闻资讯
            </h2>
            <List className="top_li">
                <List.Item></List.Item>
            </List>
            <div style={{margin:5}}>
                {
                    this.props.news.list.map(({id,title,subtitle,thumbnail,update_time})=>{
                        thumbnail=getImage(thumbnail);
                        return <Link key={id} to={`/view/news/detail/${id}`}>
                            <div key={id}  className="am-list-items">
                                <div className="am-list-thumbs">
                                    <img src={thumbnail}/>
                                </div>
                                <div className="am-list-contents">
                                    <div className="news_list_title">{title}</div>
                                    <div className="news_list_subtitle"><img src={subtitleIcon} width={26}/><span className="news_list_subtitle_txt">{subtitle}</span></div>
                                    <div className="news_list_subtitle">
                                        <img src={date} width={26}/>
                                        <span className="news_list_subtitle_txt">{_.formatTime(update_time)}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    })
                }
            </div>

        </div>
    }
}
const mapStateToProps = ({main, news}) => {
    return {
        main, news
    };
};
const mapDispatchToProps = (dispatch) => {
    return {


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsList);