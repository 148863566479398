import React, {Component} from "react";
import {Router, Route, Link} from "react-router-dom";
import {createHashHistory} from "history";
const _hashHistory = createHashHistory();
import Main from "./Main";
import Test from "./Test";

import Stage from "./Stage"
import Co from "./modules/co/index";
export default () => {
    return <Router history={_hashHistory}>
        <div style={{height: "100%"}}>
            <Route path="/" exact component={Main}/>
            <Route path="/view"    component={Main}/>
            <Route path="/test" component={Test}/>
            <Route path="/co" component={Co}/>
        </div>
    </Router>
}
const checkGlobal = () => {
    // if(_hashHistory.location.pathname=="/"||_hashHistory.location.pathname=="/login")
    // {
    //     document.body.style.overflowX="auto";
    //     document.body.style.overflowY="auto";
    // }else
    // {
    //
    //     document.body.style.overflowX="hidden";
    //     document.body.style.overflowY="hidden";
    //
    // }
};
_hashHistory.listen((param) => {
    checkGlobal();
});
checkGlobal();
export {_hashHistory as hashHistory};
