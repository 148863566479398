import React,{Component} from "react";
import {Router, Route, Link} from "react-router-dom";
import Success from "./Success";
import Fail from "./Fail";
import Co from "./Co";
export default class index extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    render()
    {
        return <div className="co_container">
            <Route path={`/co`} exact  component={Co}/>
            <Route path={`/co/ok`}  component={Success}/>
            <Route path={`/co/fail`}  component={Fail}/>
        </div>
    }
}