/**
 * Created by ahuiwang on 2017/6/27.
 */
import mainVO from "./main.vo";
import homeVO from "./home.vo";
import bizVO from "./biz.vo";
import newsVO from "./news.vo";
import coVO from "./co.vo";
export class ModelLocator {
    /**设置注册模块列表*/
    static get models() {
        return [
            mainVO,
            homeVO,
            bizVO,
            newsVO,
            coVO
        ];
    }
}
