import React,{Component} from "react";
import {Icon, NavBar, Result} from "antd-mobile";
import IconX from "../../components/IconX";
export default class Fail extends Component
{
    render()
    {
        return <div className="co_result">
            <NavBar
                className="co_header"
                icon={<Icon type="left" />}
                onLeftClick={() => {
                    this.setState({
                        result:""
                    });
                    this.props.history.push("/co")

                }}
            >
                <div className="co_title"><IconX type="co" size={17}/><span>谈合作</span></div>
            </NavBar>
            <Result
            img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }}/>}
            title="无法完成操作"
            message="请登录 https://qeao.com"
        /></div>;
    }
}