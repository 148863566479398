/**
 * Convert an Uint8Array into a string.
 *
 * @returns {String}
 */
export function Decodeuint8arr(uint8array){
    return new TextDecoder("utf-8").decode(uint8array);
}

/**
 * Convert a string into a Uint8Array.
 *
 * @returns {Uint8Array}
 */
export function Encodeuint8arr(myString){
    return new TextEncoder("utf-8").encode(myString);
}
/**
 *
 * 读流 stream为ReadableStream
 * process funcion 回调 ,参数第一个是返回值，第二个是是否已完成
 * isTrans 是否转换成字符串，默认转换
 * */
export function fetchStream(stream,process,isTrans) {
    isTrans=isTrans==undefined?true:!!isTrans;
    const reader = stream.getReader();
    let charsReceived = 0;
    // read() returns a promise that resolves
    // when a value has been received
    reader.read().then(function processText({ done, value }) {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
            console.log("Stream complete");
            // para.textContent = value;
            process(value,true);
            return;
        }
        // value for fetch streams is a Uint8Array
        charsReceived += value.length;
        process(isTrans?Decodeuint8arr(value):value);
        // Read some more, and call this function again
        return reader.read().then(processText);
    });
}

// export async function fetchStream()
// {
//     return fetch(url).then(function(response) {
//         // get the size of the request via the headers of the response
//         contentLength = response.headers.get('Content-Length');
//
//         var pump = function(reader) {
//             return reader.read().then(function(result) {
//                 // if we're done reading the stream, return
//                 if (result.done) {
//                     return;
//                 }
//
//                 // retrieve the multi-byte chunk of data
//                 var chunk = result.value;
//
//                 var text = '';
//                 // since the chunk can be multiple bytes, iterate through
//                 // each byte while skipping the byte order mark
//                 // (assuming UTF-8 with single-byte chars)
//                 for (var i = 3; i < chunk.byteLength; i++) {
//                     text += String.fromCharCode(chunk[i]);
//                 }
//
//                 // append the contents to the page
//                 document.getElementById('content').innerHTML += text;
//
//                 // report our current progress
//                 progress += chunk.byteLength;
//                 console.log(((progress / contentLength) * 100) + '%');
//
//                 // go to next chunk via recursion
//                 return pump(reader);
//             });
//         }
//
//         // start reading the response stream
//         return pump(response.body.getReader());
//     })
//         .catch(function(error) {
//             console.log(error);
//         });
// });
// }
