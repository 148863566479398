import React, {Component} from "react";
import {connect} from "react-redux";
import { Button,WingBlank,List,Icon} from 'antd-mobile';
import _ from "../../utils/_";
import { Router, Route, Link } from "react-router-dom";
import BizList from "./BizList";
import BizDetail from "./BizDetail";
class Biz extends Component
{
    render()
    {
        return  <div className="home_container">
            <Route path={`/view/biz`} exact  component={BizList}/>
            <Route path={`/view/biz/detail/:id`}  component={BizDetail}/>
        </div>

    }
}
const mapStateToProps = ({main, biz}) => {
    return {
        main, biz
    };
};
const mapDispatchToProps = (dispatch) => {
    return {


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Biz);