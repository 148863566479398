import React,{Component} from "react";
import Loader from "react-loaders";
import 'loaders.css/loaders.css';
import logo from "./theme/images/logo.png";
export default class Stage extends Component
{
    constructor(props,context)
    {
        super(props,context);
        this.props.history.replace("/view")
    }

    render()
    {
        return <div class="stage_root">

        </div>
    }
}