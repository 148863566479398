import React,{Component} from "react";
import { ActionSheet, WingBlank, WhiteSpace, Button, Toast } from 'antd-mobile';
export default class Test extends Component
{
    constructor() {
        super();
        this.state = {
            clicked: 'none',
            clicked1: 'none',
            clicked2: 'none',
        };
    }

    dataList = [
        { url: 'OpHiXAcYzmPQHcdlLFrc', title: '发送给朋友' },
        { url: 'wvEzCMiDZjthhAOcwTOu', title: '新浪微博' },
        { url: 'cTTayShKtEIdQVEMuiWt', title: '生活圈' },
        { url: 'umnHwvEgSyQtXlZjNJTt', title: '微信好友' },
        { url: 'SxpunpETIwdxNjcJamwB', title: 'QQ' },
    ].map(obj => ({
        icon: <img src={`https://gw.alipayobjects.com/zos/rmsportal/${obj.url}.png`} alt={obj.title} style={{ width: 36 }} />,
        title: obj.title,
    }));

    showActionSheet = () => {
        const BUTTONS = ['Operation1', 'Operation2', 'Operation2', 'Delete', 'Cancel'];
        ActionSheet.showActionSheetWithOptions({
                options: [],
                title:"Aaa",
                message: <Button>hello</Button>,
                maskClosable: true,
                'data-seed': 'logId',
            },
            (buttonIndex) => {
                this.setState({ clicked: BUTTONS[buttonIndex] });
            });
    }

    showShareActionSheet = () => {
        ActionSheet.showShareActionSheetWithOptions({
                options: [{
                    icon:"bbb",
                    title:"A"
                }],
                // title: 'title',
                message: 'I am description, description, description',
            },
            (buttonIndex) => {
                this.setState({ clicked1: buttonIndex > -1 ? this.dataList[buttonIndex].title : 'cancel' });
                // also support Promise
                return new Promise((resolve) => {
                    resolve();
                    // Toast.info('closed after 1000ms');
                    // setTimeout(resolve, 1000);
                });
            });
    }

    showShareActionSheetMulpitleLine = () => {
        const data = [[...this.dataList, this.dataList[2]], [this.dataList[3], this.dataList[4]]];
        ActionSheet.showShareActionSheetWithOptions({
                options: data,
                message: 'I am description, description, description',
            },
            (buttonIndex, rowIndex) => {
                this.setState({ clicked2: buttonIndex > -1 ? data[rowIndex][buttonIndex].title : 'cancel' });
            });
    }

    showActionSheetBadge = () => {
        const BUTTONS = ['Operation1', 'Operation2', 'Operation3', 'Operation4', 'Operation5', 'Delete', 'Cancel'];
        const BADGES = [{
            index: 1,
            dot: true,
        }, {
            index: 2,
            text: 3100,
        }, {
            index: 3,
            text: '推荐',
        }, {
            index: 4,
            text: '···',
        }];
        ActionSheet.showActionSheetWithOptions({
                options: BUTTONS,
                cancelButtonIndex: BUTTONS.length - 1,
                destructiveButtonIndex: BUTTONS.length - 2,
                badges: BADGES,
                // title: 'title',
                message: 'I am description, description, description',
                maskClosable: true,
                'data-seed': 'logId',

            },
            (buttonIndex) => {
                this.setState({ clicked: BUTTONS[buttonIndex] });
            });
    }

    render() {
        return (<WingBlank>
            <Button onClick={this.showActionSheet}>showActionSheet</Button>
            <WhiteSpace />
            <Button onClick={this.showActionSheetBadge}>showActionSheet&Badge</Button>
            <WhiteSpace />
            <Button onClick={this.showShareActionSheet}>showShareActionSheet</Button>
            <WhiteSpace />
            <Button onClick={this.showShareActionSheetMulpitleLine}>showShareActionSheetMulpitleLine</Button>
        </WingBlank>);
    }
}