import Cookies from "js-cookie";
import _ from "../utils/_";
import {fetchNews,fetchNewsInfo} from "../services/news.server";
export default {
    namespace: "news",
    state: {
        loading: false,
        list:[],
        info:{}
    },
    subscriptions: {
        condition:["view/news"],
        setup({dispatch}) {  // eslint-disable-line
            dispatch({
                type:"getNews"
            })
        }
    },
    effects: {
        *getNews({payload,success,error}, {dispatch, select, history}) {
            const {code, result, msg} = yield fetchNews();
            if (result) {
                yield dispatch({
                    type: "updateNews",
                    payload: result
                })
            }
        },
        *getNewsInfo({payload,success,error}, {dispatch, select, history}) {
            yield dispatch({
                type:"resetNewsInfo"
            });
            const {code,result,msg}=yield fetchNewsInfo(payload);
            if(result)
            {
                yield dispatch({
                    type:"updateNewsInfo",
                    payload:result
                })
            }
            yield dispatch({
                type:"updateLoading",
                payload:false
            })
        }

    },
    reducers: {
        updateNews(state, {payload}) {
            state.list=[...new Set(payload)];
            return {...state};
        },
        updateNewsInfo(state,{payload})
        {
            state.info={...payload};
            return {...state};
        },
        resetNewsInfo(state,{payload})
        {
            state.info={};
            state.loading=true;
            return {...state};
        },
        updateLoading(state,{payload})
        {
            state.loading=!!payload;
            return {...state};
        }
    }
}
