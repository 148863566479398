import React,{Component} from "react";
import fav from "../theme/images/fav.png";
import logo from "../theme/images/logo_b.png";
import {Grid} from "antd-mobile";
import icon_web from "../theme/images/icons/web.png";
import icon_web_app from "../theme/images/icons/web_app.png";
import icon_app from "../theme/images/icons/app.png";
import icon_win from "../theme/images/icons/windows.png";
import icon_linux from "../theme/images/icons/linux.png";
import icon_mac from "../theme/images/icons/mac.png";
import icon_weixin from "../theme/images/icons/weixin.png";
import icon_alipay from "../theme/images/icons/alipay.png";
import icon_weibo from "../theme/images/icons/weibo.png";
import icon_baidu from "../theme/images/icons/baidu.png";
import icon_zhifu from "../theme/images/icons/zhifu.png";
import icon_cust from "../theme/images/icons/cust.png";
import {List} from "antd-mobile";
export default class US extends Component
{
    constructor(props)
    {
        super(props)
    }
    render()
    {
        return <div className="news_list">
            <h2>
                关于鹊奥
            </h2>
            <List  className="top_li" >
                <List.Item></List.Item>
            </List>
            <div className="detail_content_card">
                <p>
                    西安鹊奥网络科技有限公司 （<label>Qeao.com</label>） <br/>简称鹊奥（<label>Qeao</label>）。是一家专门从事软件开发、销售、服务的科技公司。 公司于2017年11月创立，位于十三朝古都西安。2018年2月，“<label>Qeao.com</label>”正式启用。2019年3月鹊奥（<label>Qeao</label>）正式入驻新浪微博。</p>

                <p>鹊奥（<label>Qeao</label>）现有业务主要包括以下：网站开发建设（包括移动端webapp）；windows,linux,macos桌面系统软件开发；微信小程序开发，微信公众号开发，支付宝小程序开发，微博小程序开发，微信、支付宝支付对接等；移动APP开发，以及其他类型软件或互联网产品定制、升级、改造等。</p>

            </div>

            <div>
                <Grid
                    activeStyle={false}
                    data={[{
                    icon:icon_web,
                    text:"网站建设"
                },
                    {
                        icon:icon_web_app,
                        text:"WebApp"
                    },
                    {
                        icon:icon_app,
                        text:"APP开发"
                    },
                    {
                        icon:icon_win,
                        text:"Windows程序开发"
                    },
                    {
                        icon:icon_linux,
                        text:"Linux程序开发"
                    },
                    {
                        icon:icon_mac,
                        text:"Mac程序开发"
                    },{
                        icon:icon_weixin,
                        text:"微信开发"
                    },
                    {
                        icon:icon_alipay,
                        text:"支付宝开发"
                    },
                    {
                        icon:icon_weibo,
                        text:"微博开发"
                    },
                    {
                        icon:icon_baidu,
                        text:"百度平台开发"
                    },
                    {
                        icon:icon_zhifu,
                        text:"第三方对接"
                    },
                    {
                        icon:icon_cust,
                        text:"定制开发"
                    }
                    ]}
                      columnNum={3}
                      renderItem={({icon,text})=> (
                          <div >
                              <img src={icon} style={{ width: 40,height:40 }} alt="" />
                              <div style={{ color: '#888', fontSize: '14px', marginTop: '12px' }}>
                                  <span>{text}</span>
                              </div>
                          </div>
                      )}
                >

                </Grid>
            </div>
        </div>
    }
}