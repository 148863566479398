import React,{Component} from "react";
import PropTypes from "prop-types";
export default class IconX extends Component
{
    static get propTypes()
    {
        return {
            type:PropTypes.string,
            size:PropTypes.number
        }
    }
    static get defaultProps()
    {
        return {
            size:24
        }
    }
    render()
    {
        return <i className={`icon_x icon_${this.props.type}`} style={{
            width:this.props.size,
            height:this.props.size
        }}></i>
    }
}