import Match from "path-match";
export const match=(router,pathname,option={
    sensitive: false,
    strict: false,
    end: false
})=>{
    const _Match=new Match(option);
    let Do=_Match(router);
    return Do(pathname);
};
export const parseParam=(router,pathname)=>{
    let result={};
    const suffixReg=/\/$/;
    router=router.replace(suffixReg,"");
    let actionParam=match(`${router}/:action`,pathname);
    if(actionParam)
    {
        let {action}=actionParam;
        result.action=action;
        if(action)
        {
            let idParam=match(`${router}/${action}/:id`,pathname);
            if(idParam)
            {
                let {id}=idParam;
                result.id=id;
            }
        }
    }
    return result;
};