import React,{Component} from "react";
import connect from "react-redux/es/connect/connect";
import {parseParam} from "../../utils/Match";
import PropTypes from "prop-types";
import {getImage} from "../../utils/Img";
import _ from "../../utils/_";
import {Icon, NavBar,Grid} from "antd-mobile";
import ShareTo from "../../components/ShareTo";
import IconX from "../../components/IconX";
import {Popover}from "antd-mobile";
import Loading from "../../components/Loading";
class NewsInfo extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    static get contextTypes() {
        return {
            history: PropTypes.object
        }
    }
    state={
        visible:false
    }
    getLabel()
    {

    }
    render()
    {
        return <div  className="news_info" >
            <Loading loading={this.props.news.loading} text=""/>
            <div className="news_info_header">
                <div className="news_info_title">
                    {
                        this.props.news.info.title
                    }
                </div>
                {/*<div className="news_info_subtitle">*/}
                {/*{*/}
                {/*this.props.news.info.subtitle*/}
                {/*}*/}
                {/*</div>*/}
                <div className="news_info_subtitle">
                    {_.formatTime(this.props.news.info.update_time)}
                </div>
            </div>

            <div>
                <img src={getImage(this.props.news.info.thumbnail)}/>
            </div>
            <div className="news_info_content" dangerouslySetInnerHTML={{__html:this.props.news.info.content}}>

            </div>

        </div>
    }
    componentDidMount()
    {
        let {action,id}=parseParam("/view/news",this.context.history.location.pathname);
        if(action=="detail"&&id)
        {
            this.props.getNewsInfo(id);
        }
    }
}
const mapStateToProps = ({main, news}) => {
    return {
        main, news
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNewsInfo:(payload)=>{
            dispatch({
                type:"news/getNewsInfo",
                payload
            })
        }

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsInfo);