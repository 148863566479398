import React, {Component} from "react";
import logo from "./theme/images/logo.png";
import bei from "./theme/images/bei.png";
import {connect} from "react-redux";
import {Router, Route, Link} from "react-router-dom";
import Home from "./modules/Home";
import News from "./modules/news/index";
import Biz from "./modules/biz/index";
import Us from "./modules/US";
import Contact from "./modules/Contact";
import Nav from "./Nav";
import {Drawer, WingBlank,List,Icon,InputItem,Button,SearchBar,NoticeBar} from "antd-mobile";
import Header from "./Header";
import $ from "jquery";
import cn from "./theme/images/flag_cn_drawer.png";
import PropTypes from "prop-types";
class Main extends Component
{
    constructor(props,context)
    {
        super(props,context);

    }
    componentDidMount()
    {


    }
    getChildContext() {
        return {
            history: this.props.history,
        }
    }
    static get childContextTypes() {
        return {
            history: PropTypes.object
        }
    }
    state = {
        open: false,
        notice:false
    };
    onOpenChange = (...args) => {
        let open=!this.state.open;
        this.setState({ open });
        if(open)
        {
            $(".wrap").addClass("fixed");
            $("body").addClass("no_scroll");
            $("html").addClass("no_scroll");
        }else
        {
            $(".wrap").removeClass("fixed");
            $("body").removeClass("no_scroll");
            $("html").removeClass("no_scroll");
        }

    };
    render()
    {
        const sidebar = (<List ref={()=>{}} className="header_drawer_cont">
            {[


                {
                label:"关于鹊奥",
                id:"about",
                    url:"/view/us"

            },{
                label:"业务范围",
                id:"biz",
                    url:"/view/biz"
            },{
                label:"新闻资讯",
                id:"news",
                    url:"/view/news"
            },{
                label:"联系我们",
                id:"contact",
                    url:"/view/contact"
            },
                {
                label:"隐私政策",
                id:"cookie",
                    hide:true,
                    url:"/view/privacy"
            },
                {
                    label:"返回首页",
                    id:"home",
                    url:"/"
                },
            ].map(({label,id,url,hide}) => {
                if(hide)return;
                if(id=="search")
                {
                    return <List.Item key={id}>
                        <SearchBar
                            placeholder="请输入搜索内容"
                        />
                    </List.Item>;
                }
                if(id=="flag")
                {
                    return <List.Item key={id} >
                        <div className="flex_row" >
                            <span><img src={cn}/></span>
                            <span style={{paddingLeft:8}}>{label}</span>
                        </div>
                    </List.Item>
                }
                return <List.Item key={id} multipleLine extra={<Icon type="right"/>} onClick={()=>{
                   this.onOpenChange();
                    this.props.history.push(url);
                }
                }>
                    <div className="flex_row"> <span className="right_row_icon"></span><span >{label}</span></div>
                </List.Item>
                // if (index === 0) {
                //     return (<List.Item key={index}
                //                        thumb="https://zos.alipayobjects.com/rmsportal/eOZidTabPoEbPeU.png"
                //                        multipleLine
                //     >Category</List.Item>);
                // }
                // return (<List.Item key={index}
                //                    thumb="https://zos.alipayobjects.com/rmsportal/eOZidTabPoEbPeU.png"
                // >Category{index}</List.Item>);
            })}
        </List>);
        return <Drawer
                touch={false
                }
                className={`my-drawer ${this.state.open&&"show_drawer_open"||""} ${this.state.notice&&"w_notice"||""}`}
                style={{ minHeight: document.documentElement.clientHeight }}
                contentStyle={{ color: '#A6A6A6', textAlign: 'center', paddingTop: 42 }}
                sidebar={sidebar}
                position="top"
                transitions={true}
                open={this.state.open}
                onOpenChange={this.onOpenChange}
            >
            <div className={`wrap `}>
                {this.state.notice&&<NoticeBar mode="closable"
                                                className="main_notice"
                                                marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}
                                                onClick={()=>{
                                                    this.setState({
                                                        notice:false
                                                    });
                                                    localStorage.setItem("qeao-notice-hide",1);
                                                }
                                                }
                                                action={<span style={{ color: '#a1a1a1' }}>不再提示</span>}>
                    移动端正在建设中，感谢您一直以来的关注
                </NoticeBar>}
            <Header open={this.state.open} className={this.state.open?"open_header":""} onClickMenu={()=>{
                this.onOpenChange()
            }
            }
                    onClickEst={()=>{
                        this.props.history.push("/co")
                    }
                    }
            />
                <div className="flex_col">
                    <WingBlank>
                        <main className="main">
                            <Nav type={this.props.history.location.pathname}/>
                            {
                                this.props.children
                            }
                            <div className="main_cont">
                                <Route path="/" exact component={Home}/>
                                <Route path="/view/news" component={News}/>
                                <Route path="/view/biz" component={Biz}/>
                                <Route path="/view/us" component={Us}/>
                                <Route path="/view/contact" component={Contact} />
                            </div>
                        </main>
                    </WingBlank>
                </div>
                <footer>
                    <div className="footer_logo">
                        <img src={logo}/>
                    </div>
                    <div className="footer_v_column">
                        <div className="bei_box">
                            <span><img src={bei}/></span>
                            <a className="bei" href="http://www.beian.miit.gov.cn/" target="_blank">
                                陕ICP备17020946号
                            </a>

                        </div>
                    </div>
                    <div className="footer_btns">
                        <button className="download_btn" onClick={()=>{
                            this.props.history.push("/co");
                        }}>
                            谈合作
                        </button>
                        {/*<button className="download_btn bies_btn">洽谈业务</button>*/}
                    </div>
                    <div className="footer_txt">
                        {/*COPYRIGHT &copy; 2020-2021 QEAO.COM*/}
                        版权所有  鹊奥网络科技
                    </div>
                </footer></div>
            {/*<Est />*/}
        </Drawer>

    }
}
const mapStateToProps = ({main}) => {
    return {
        main
    };
};
const mapDispatchToProps = (dispatch) => {
    return {


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);