import request from '../utils/request';
import url from "url";
import $ from "jquery";
import Cookies from 'js-cookie';
export async function fetchCoop(option)
{
    return request(`/api/enquiry`,{
        method:"PUT",
        headers:{
            "X-Requested-With":"XMLHttpRequest",
            "Content-Type":"application/json;charset=UTF-8"
        },
        body:JSON.stringify(option)
    });
}


