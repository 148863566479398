import React,{Component} from "react";
import PropTypes from "prop-types";
import {ActionSheet,Button,Toast,WingBlank,WhiteSpace,Picker,List,Card,TextareaItem,NavBar,Icon,InputItem,Steps,Result} from "antd-mobile";
import { createForm } from 'rc-form';
import IconX from "./IconX";
const Step = Steps.Step;
import _ from "../utils/_";
import connect from "react-redux/es/connect/connect";
class ToCoop extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    state={
        project:["1"],
        est:["1"],
        rm:Math.random(),
    };
    getCaptcha()
    {
        return <span>
            <img src={`https://qeao.com/api/captcha?t=${this.state.rm}`}  style={{width:90,height:30}} onClick={()=>{
                this.setState({
                    rm:Math.random()
                })
            }
            }/>
        </span>
    }
    render()
    {
        const { getFieldProps ,getFieldError} = this.props.form;
        return  <div className="co_box">
            <NavBar
                className="co_header"
                icon={<Icon type="left" />}
                onLeftClick={() => {
                    this.props.onBack();

                }}
            >
                <div className="co_title"><IconX type="co" size={17}/><span>谈合作</span></div>
            </NavBar>

               <Card full={false} className="to_coop_card" >
                    <Card.Header

                        extra={""}
                    />
                    <Card.Body>
                        <Steps current={-1} direction="horizontal" size="small">
                            <Step title="第一步" description="填写表单"  icon="right"/>
                            <Step title="第二步" description="输入验证码" icon="right"/>
                            <Step title="完成" description="等待和您联系" icon="check"/>
                        </Steps>
                        <WhiteSpace/>
                        <List className="to_coop_list" renderHeader={()=>{
                            return "填写需求"}
                        }>

                            <Picker cols={1} title="选择项目" extra="请选择"
                                    {...getFieldProps('project', {
                                        initialValue: this.state.project,
                                    })}
                                    onChange={(project)=>{
                                        this.setState({
                                            project:[...new Set(project)]
                                        })
                                    }
                                    }
                                    data={[{
                                        label:"企业网站",
                                        value:"1"
                                    },{
                                        label:"网上商城",
                                        value:"2"
                                    },{
                                        label:"桌面开发",
                                        value:"3"
                                    },{
                                        label:"微信公众号",
                                        value:"4"
                                    },{
                                        label:"微信小程序开发",
                                        value:"5"
                                    },{
                                        label:"支付宝小程序开发",
                                        value:"6"
                                    },{
                                        label:"第三方对接开发",
                                        value:"7"
                                    },
                                        {
                                            label:"其他",
                                            value:"8"
                                        }
                                    ]}>
                                <List.Item arrow="horizontal" >选择项目</List.Item>
                            </Picker>
                            <Picker cols={1} title="您的预算" extra="请选择"
                                    {...getFieldProps('est', {
                                        initialValue: this.state.est,
                                    })}

                                    onChange={(est)=>{
                                        this.setState({
                                            est:[...new Set(est)]
                                        })
                                    }
                                    }
                                    data={[
                                        {
                                            label:"10000以下",
                                            value:"1"
                                        },{
                                            label:"10000~30000",
                                            value:"2"
                                        },
                                        {
                                            label:"30000~50000",
                                            value:"3"
                                        },
                                        {
                                            label:"50000~100000",
                                            value:"4"
                                        },
                                        {
                                            label:"100000以上",
                                            value:"5"
                                        }
                                    ]}
                            >
                                <List.Item arrow="horizontal"
                                           key="est"
                                >选择预算</List.Item>
                            </Picker>
                            <TextareaItem
                                {...getFieldProps('remark',{
                                    rules:[{
                                        required: true, message: '请输入您的需求',type:"string"
                                    }]
                                })}
                                title="您的需求"
                                placeholder="简单描述您的需求"
                                labelNumber={5}
                                rows={5}
                                clear
                                error={!!getFieldError('remark')}
                                onErrorClick={()=>{
                                    Toast.fail(getFieldError("remark"))
                                }
                                }
                            />

                        </List>
                        <List className="to_coop_list" renderHeader={()=>{
                            return "留下联系方式"
                        }
                        }>
                            <InputItem placeholder="姓名或公司名"
                                       key="name"
                                       {
                                           ...getFieldProps("name",{
                                               rules:[{
                                                   required: true, message: '请输入你的称呼',type:"regexp"
                                               }]
                                           })
                                       }
                                       clear
                                       error={!!getFieldError('name')}
                                       onErrorClick={()=>{
                                           Toast.fail(getFieldError("name"))
                                       }
                                       }
                            >您的称呼</InputItem>
                            <InputItem
                                key="phone"
                                placeholder="手机号码或固话"
                                // error={this.state.hasError}
                                // onErrorClick={this.onErrorClick}
                                // onChange={this.onChange}
                                // value={this.state.value}
                                {
                                    ...getFieldProps("phone",{
                                        rules:[{
                                            required: true, message: '请输入您的电话',type:"regexp"
                                        }]
                                    })
                                }
                                clear
                                error={!!getFieldError('phone')}
                                onErrorClick={()=>{
                                    Toast.fail(getFieldError("phone"))
                                }
                                }
                            >手机号码</InputItem>
                            <InputItem
                                required
                                key="email"
                                placeholder="请输入您的邮箱"
                                {
                                    ...getFieldProps("email",{
                                        rules:[{
                                            required: true, message: '请输入您的邮箱',type:"email"
                                        }]
                                    })
                                }
                                clear
                                error={!!getFieldError('email')}
                                onErrorClick={()=>{
                                    Toast.fail(getFieldError("email"))
                                }
                                }
                                // error={this.state.hasError}
                                // onErrorClick={this.onErrorClick}
                                // onChange={this.onChange}
                                // value={this.state.value}
                            >邮箱</InputItem>

                        </List>
                        <List className="to_coop_list" renderHeader={()=>{
                            return "输入验证码"}
                        }>
                            <InputItem placeholder="验证码"
                                       width={100}
                                       key="captcha"
                                       {
                                           ...getFieldProps("captcha",{
                                               rules:[{
                                                   len:4,
                                                   max:4,
                                                   required: true, message: '请输入验证码',type:"string"
                                               }]
                                           })
                                       }
                                       clear
                                       error={!!getFieldError('captcha')}
                                       onErrorClick={()=>{
                                           Toast.fail(getFieldError("captcha"))
                                       }
                                       }
                                       extra={this.getCaptcha()}
                            >验证码</InputItem>
                            <List.Item className="to_coop_footer">
                                <Button type="primary" onClick={()=>{
                                    this.props.form.validateFields({ force: true }, (error) => {
                                        if (!error) {
                                            let obj=this.props.form.getFieldsValue();
                                            obj.project=obj.project[0];
                                            obj.est=obj.est[0];
                                            obj.mobile=true;
                                            this.props.onSubmit(obj);

                                        } else {

                                            // let arrs=[];
                                            // _.mapObject(error,(val,key)=>{
                                            //     arrs.push(val.errors[0].message);
                                            // });
                                            // Toast.fail(arrs.map((error)=>{
                                            //     return <div>{error}</div>
                                            // }))
                                            // alert(error);
                                            Toast.offline("必填项未填写")
                                        }
                                        this.setState({
                                            rm:Math.random()
                                        })
                                    });
                                }
                                }>提交</Button>
                                <Button>重填</Button>
                            </List.Item>
                        </List>

                    </Card.Body>
                </Card>


        </div>

    }
}
export default createForm()(ToCoop);