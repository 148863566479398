import React, {Component} from "react";
import PropTypes from "prop-types";
import {List} from "antd-mobile";
export default class BizListRender extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    static get contextTypes() {
        return {
            history: PropTypes.object
        }
    }
    static get propTypes()
    {
        return {
            data:PropTypes.array
        }
    }
    render()
    {
        return  <List className="biz_list">
            {
                this.props.data.map(({id,icon})=>{
                    return  <List.Item key={id} onClick={()=>{
                        this.context.history.push(`/view/biz/detail/${id}`);
                    }
                    }>
                        <img src={icon} alt="loading"/>
                    </List.Item>
                })
            }
        </List>
    }
}