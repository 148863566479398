import React, {Component} from "react";
import './App.css';
import { Provider } from 'react-redux';
import store from "./pigsty";
import Router from "./router";
import 'antd-mobile/dist/antd-mobile.less';
export default class App extends Component{
    constructor(props,context)
    {
        super(props,context);
    }
    render()
    {
        return <Provider store={store}>
            <div className="stage">
                <Router/>
            </div>
        </Provider>
    }
}


