import Cookies from "js-cookie";
import _ from "../utils/_";
export default {
    namespace: "main",
    state: {
        loading: false
    },
    subscriptions: {
        condition:[""],
        setup({dispatch}) {  // eslint-disable-line
            console.log("main")
        }
    },
    effects: {
        *toLogin({payload,success,error}, {dispatch, select, history}) {

        }

    },
    reducers: {
        updateLogin(state, {payload}) {
            return {...state};
        }
    }
}
