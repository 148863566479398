import React,{Component} from "react";
import PropTypes from "prop-types";
import IconX from "./IconX";
import {ActionSheet,Button,Toast} from "antd-mobile";
import {stopPropagation} from "../utils/event";
export default class ShareTo extends Component
{
    static get propTypes()
    {
        return {
            type:PropTypes.string,
            size:PropTypes.number,
            message:PropTypes.node
        }
    }
    static get defaultProps()
    {
        return {
            size:24,
            message:"请点击线面按钮进行分享到对应平台"
        }
    }
    get getShareBtns()
    {
        return [{
            icon:"alipay",
            label:"支付宝"
        },{
            icon:"qq",
            label:"QQ"
        },{
            icon:"weibo",
            label:"微博"
        },{
            icon:"weixin",
            label:"微信"
        },{
            icon:"shq",
            label:"生活圈"
        }]
    }
    get data()
    {
        return [
            {
                icon:<IconX type="alipay" size={36}/>,
                title:"支付宝"
            },
            {
                icon:<IconX type="qq" size={36}/>,
                title:"支付宝"
            },
            {
                icon:<IconX type="weibo" size={36}/>,
                title:"微博"
            },
            {
                icon:<IconX type="weixin" size={36}/>,
                title:"微信"
            },
            {
                icon:<IconX type="shq" size={36}/>,
                title:"生活圈"
            }
        ]
    }
    render()
    {
        return <div className="share_to_container" onClick={()=>{
            ActionSheet.showShareActionSheetWithOptions({
                options:this.data,
                message:this.props.message
            },(buttonIndex, rowIndex)=>{
                return new Promise((resolve) => {
                    console.log("xxx",buttonIndex);
                   // Toast.info('closed after 1000ms');
                   // setTimeout(resolve, 1000);
                    if(buttonIndex!=3)
                    {
                        if(buttonIndex!=-1)
                        {
                            Toast.success("分享成功")
                        }

                        resolve();
                    }
                });
            })
        }
        }>
            {this.props.children}
        </div>
    }
}