import design from "../../theme/images/design.jpg";
import web from "../../theme/images/web.jpg";
import windows from "../../theme/images/windows.jpg";
import mac from "../../theme/images/mac.jpg";
import linux from "../../theme/images/linux.jpg";
import other from "../../theme/images/other.jpg";
import React,{Component} from "react";

import {WingBlank} from "antd-mobile";
export default  [{
    id:"design",
    label:"视觉设计",
    icon:design,
    content:()=>{
        return <div>

            <p>
                <label>界面设计</label><span className="space"></span>包括网页视觉设计和各类手执（手机、pad等等）系统的界面视觉设计。可提供动态可交互展示界面。
            </p>
            <p>
                <label>标志设计</label><span className="space"></span>提供公益性、文化性、商业性的标志设计，提升品牌识别度。
            </p>
            <p>
                <label>图像设计</label><span className="space"></span>运用图像设计为企业提供统一的形象、产品包装风格等。
            </p>


        </div>
    }
},{
    id:"web",
    label:"网站建设",
    icon:web,
    recommend:true,
    content:()=>{
        return <div>
            <p>
                <label>企业品牌官网</label><span className="space"></span>官方网站是企业对外宣传交流的重要窗口，通过网站目标定位，针对性选择布局，重点展示了客户所关心的内容；并以简洁明朗的设计风格，清晰展现公司水平实力，确保一秒抓住目标用户眼球，给用户留下良好的第一印象。
            </p>
            <p>
                <label>响应式网站</label><span className="space"></span>响应式网站能够集中创建页面图片的排版大小，智能根据用户行为及使用的设备环境进行相应布局
            </p>
            <p>
                <label>营销型网站</label><span className="space"></span>整合了各种网络营销理念和网站运营管理方法，不仅注重网站建设的专业性，更加注重网站运营管理的整个过程
            </p>
            <p>
                <label>门户信息网站</label><span className="space"></span>门户网站是指通向某类综合性互联网信息资源并提供有关信息服务的应用系统。
            </p>
            <p>
                <label>网上商城</label><span className="space"></span>满足客户在网上选择、购买，并符合该行业设计特色，支持线上线下支付的商城系统定制开发。
            </p>
            <p>
                <label>其他定制开发网站</label><span className="space"></span>满足客户定制需求的特性网站，展现其品牌独特性。
            </p>
        </div>
    }
},{
    id:"windows",
    label:"windows程序开发",
    icon:windows,
    recommend:false,
    content:()=>{
        return <div>
            <p>
                支持win7以上包括32和64位应用程序开发。界面华丽、高效、易用。适合各个行业的定制开发业务。
            </p>
        </div>
    }
},{
    id:"mac",
    label:"Mac程序开发",
    content:()=>{
       return <div>
           <p>
               支持MacOS系统上应用程序开发，同时也支持iOS、iPadOS移动端APP开发。
           </p>
       </div>
    },
    icon:mac
},{
    id:"linux",
    label:"Linux程序开发",
    content:()=>{
        return <div>
            <p>
                支持主流Linux系统（Ubuntu、CentOS等）上应用程序开发、维护、部署。
            </p>
        </div>
    },
    icon:linux
},{
    id:"other",
    label:"第三方对接",
    icon:other,
    recommend:true,
    content:()=>{
        return <div>
            <p>
                <label>微信对接</label><span className="space"></span>
                微信小程序开发，
                微信公众平台开发， 微信支付接入开发
            </p>

            <p>
                <label>支付宝对接</label><span className="space"></span>
                支付宝小程序开发，
                支付宝支付接入开发
            </p>
            <p>
                <label>微博对接</label><span className="space"></span>
                微博小程序开发，
                微博登录接入
            </p>
            <p>
                <label>百度小程序开发</label><span className="space"></span>
            </p>
            <p>
                <label>其他第三方开发平台接入开发</label>
            </p>
        </div>
    },
}]