import React,{Component} from "react";
import {Icon, NavBar, Result} from "antd-mobile";
import IconX from "../../components/IconX";
export default class Success extends Component
{
    render()
    {
        return <div className="co_result">
            <NavBar
                className="co_header"
                icon={<Icon type="left" />}
                onLeftClick={() => {
                    this.setState({
                        result:""
                    });
                    this.props.history.push("/co")

                }}
            >
                <div className="co_title"><IconX type="co" size={17}/><span>谈合作</span></div>
            </NavBar><Result
            img={<Icon type="check-circle" className="spe" style={{ fill: '#8BC34A' }} />}
            title="提交成功"
            message="我们会尽快和您联系，感谢您的合作"
        /></div>;
    }
}