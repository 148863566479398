import React,{Component} from "react";
import PropType from "prop-types";
import {Router, Route, Link} from "react-router-dom";
export default class Nav extends Component
{
    constructor(props,context)
    {
        super(props,context);
    }
    static get propTypes()
    {
        return {
            type:PropType.string
        }
    }
    static get defaultProps()
    {
        return {
            type:"/"
        }
    }
    get navs()
    {
        return [{
            id:"/",
            className:"n_home",
            label:"首页",
            icon:"i_home"
        },{
            id:"/view/biz",
            className:"n_biz",
            label:"业务",
            icon:"i_biz"
        },
            {
                id:"/view/us",
                className:"n_us",
                label:"我们",
                icon:"i_us"
            },
            {
                id:"/view/news",
                className:"n_news",
                label:"新闻",
                icon:"i_news"
            },
            {
                id:"/view/contact",
                className:"n_contact",
                label:"联系",
                icon:"i_contact"
            }]
    }
    render()
    {
        return <nav className="main_nav">
            <div className="main_nav_container">
                {
                    this.navs.map(({id,className,label,icon})=>{
                        let selected=false;
                        if(id=="/")
                        {
                            selected=this.props.type==id;
                        }else
                        {
                            selected=this.props.type.indexOf(id)!=-1;
                        }


                        return  <Link to={id} key={id}>
                            <div className={`${className} ${selected&&"selected"||""}`} key={id}>
                                <i className={icon}></i>
                                <label>{label}</label>
                            </div>
                        </Link>
                    })
                }
            </div>
        </nav>
    }
}