import request from '../utils/request';
import url from "url";
import $ from "jquery";
import Cookies from 'js-cookie';
export async function fetchNews(top)
{
    let url="/api/news";
    let search=top?`${url}?top=${top}`:url;
    return request(search,{
        headers:{
            "X-Requested-With":"XMLHttpRequest"
        }
    });
}
export async function fetchNewsInfo(id)
{
    return request(`/api/news/${id}`,{
        headers:{
            "X-Requested-With":"XMLHttpRequest"
        }
    });
}

