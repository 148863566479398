import React, {Component} from "react";
import PropType from "prop-types";
import logo from "./theme/images/logo.png";
import {Drawer, List, Button, WingBlank,Icon} from "antd-mobile";
import Nav from "./Nav";
import Home from "./modules/Home";
import News from "./modules/News";
import {Router, Route, Link} from "react-router-dom";
import $ from "jquery";
import IconX from "./components/IconX";
export default class Header extends Component {
    constructor(props, context) {
        super(props, context);
    }
    state={
        preload:true
    };

    render() {

        return <header className={this.props.className||""}>
            <div>

                <div className="logo">
                    <img src={logo}/>
                </div>
                {/*<span className="go_est">*/}
                    {/*<Icon type="search" />*/}
                {/*</span>*/}
                {/*<span className="go_est" onClick={()=>{*/}
                    {/*this.props.onClickEst();*/}
                {/*}*/}

                {/*}>*/}
                        {/*<IconX type="write"/>*/}
                    {/*</span>*/}
                <div className={`header_menu inner-header-icon ${this.props.open?"inner-header-icon-click":"inner-header-icon-out"} ${this.state.preload?"inner_header_preload":""}`} onClick={()=>{
                    this.setState({preload:false});
                    this.props.onClickMenu()
                }
                }>
                    <span></span><span></span><span></span>
                </div>
            </div>


        </header>
    }
}