import React, {Component} from "react";
import {connect} from "react-redux";
import { Button,WingBlank,List,Icon,Carousel} from 'antd-mobile';
import subtitleIcon  from "../theme/images/subtitle.svg";
import date from "../theme/images/date.svg";
import _ from "../utils/_";
import ad1 from "../theme/images/ad1.jpg";
import ad2 from "../theme/images/ad2.jpg";
import ad3 from "../theme/images/ad3.jpg";
import {Link} from "react-router-dom";
import {getImage} from "../utils/Img";
import BizListRender from "./biz/BizListRender";
import bizData from "./biz/bizData";
import PropTypes from "prop-types";
const bData=bizData.filter((itemData)=>{
    let {recommend}=itemData;
    return recommend
});
class Home extends Component
{
    constructor(props,context) {
        super(props,context);
    }

    static get contextTypes() {
        return {
            history: PropTypes.object
        }
    }
    render()
    {
       return  <div className="home_container">
           <Carousel autoplay infinite className="home_carousel" >
               <a ><img src={ad1}/></a>
               <a><img src={ad2}/></a>
               <a><img src={ad3}/></a>
               {/*<a><img src={linux}/></a>*/}
           </Carousel>
           <List className="list_header_b">
               <List.Item >
                   <div>最新消息</div>
               </List.Item>
           </List>
           <div className="news_list">
               {
                   this.props.home.news.map(({id,title,subtitle,thumbnail,update_time})=>{
                       thumbnail=getImage(thumbnail);
                       return <Link key={id} to={`/view/news/detail/${id}`}>
                           <div key={id}  className="am-list-items">
                               <div className="am-list-thumbs">
                                   <img src={thumbnail}/>
                               </div>
                               <div className="am-list-contents">
                                   <div className="news_list_title">{title}</div>
                                   <div className="news_list_subtitle"><img src={subtitleIcon} width={26}/><span className="news_list_subtitle_txt">{subtitle}</span></div>
                                   <div className="news_list_subtitle">
                                       <img src={date} width={26}/>
                                       <span className="news_list_subtitle_txt">{_.formatTime(update_time)}</span>
                                   </div>
                               </div>
                           </div>
                       </Link>
                   })
               }

           </div>
           <List className="list_header_b">
               <List.Item >
                   <div>业务推荐</div>
               </List.Item>
           </List>
           <div>
               <BizListRender data={bData}></BizListRender>
           </div>
           <div>
               <Button type="ghost" className="home_biz_more" onClick={()=>{
                    this.context.history.push("/view/biz");
               }
               } >查看更多业务</Button>
           </div>

       </div>

    }
}
const mapStateToProps = ({main, home}) => {
    return {
        main, home
    };
};
const mapDispatchToProps = (dispatch) => {
    return {


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);