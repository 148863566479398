import React,{Component} from "react";
import PropTypes from "prop-types";
import {Icon} from "antd-mobile";
import Loader from "react-loaders";
export default class Loading extends Component
{
    constructor(props)
    {
        super(props);
    }
    static get propTypes()
    {
        return {
            loading:PropTypes.bool,
            text:PropTypes.node,
            size:PropTypes.number
        }
    }
    static get defaultProps()
    {
        return {
            loading:false,
            text:"Loading...",
            size:"lg"
        }
    }
    render()
    {
        return <div className="loading_container">
            {
                this.props.loading&& <div className="loading_content">
                    <div >
                        <div >
                            <Loader type="ball-triangle-path"  innerClassName="loading_loader" active={this.props.loading}/>
                        </div>
                    </div>
                    <div className="loading_text">
                        {this.props.text}
                    </div>
                </div>||undefined
            }
        </div>
    }
}