import React,{Component} from "react";
import {Button,Card,WhiteSpace,List} from "antd-mobile";
import IconX from "../components/IconX";
import building from "../theme/images/contact_building.jpg";
export default class Contact extends Component
{
    constructor(props)
    {
        super(props)
    }
    render()
    {
        return <div className="contact_container news_list">
            <h2>
                联系我们
            </h2>

            <List style={{ margin: '5px 0', backgroundColor: 'white' }}>
                <List.Item
                    extra={<div>
                        <a href="tel:02968987520">
                            <Button type="ghost" size="small" inline icon={<i className="i_contact"/>}> <span className="btn_txt">拨打固话</span></Button>
                        </a>

                    </div>}
                    multipleLine
                >
                    商务合作
                    <List.Item.Brief>
                        <label>电话</label>029-68987520
                        <div>

                        </div>
                    </List.Item.Brief>
                    <List.Item.Brief>
                        <label>QQ</label><a className="contact_call" href="tencent://message/?uin=1962668086&Site=Qeao.com&Menu=yes">1962668086</a>
                    </List.Item.Brief>
                    {/*<List.Item.Brief>*/}
                        {/**/}
                    {/*</List.Item.Brief>*/}
                </List.Item>
                <List.Item
                    extra={<div>
                        <a href="tel:15399000668"><Button type="ghost" size="small" inline icon={<i className="i_contact"/>}> <span className="btn_txt">拨打手机</span></Button></a>
                    </div>}
                    multipleLine
                >
                    业务洽谈
                    <List.Item.Brief>
                        <label>手机</label>15399000668
                    </List.Item.Brief>
                    <List.Item.Brief>
                        <label>Email</label><a href="mailto:bd@qeao.com" className="contact_call">
                        bd@qeao.com
                    </a>
                    </List.Item.Brief>
                </List.Item>
                <List.Item >
                    <img src={building} />
                </List.Item>
                <List.Item multipleLine className="contact_address">
                    <div>
                        陕西省西安市高新区高科尚都摩卡第9幢1单元1层10104层
                    </div>
                </List.Item>
            </List>

        </div>
    }
}