import Cookies from "js-cookie";
import _ from "../utils/_";
import {fetchNews} from "../services/news.server";
export default {
    namespace: "home",
    state: {
        loading: false,
        news:[]
    },
    subscriptions: {
        condition:["/"],
        setup({dispatch}) {  // eslint-disable-line
            dispatch({
                type:"getNews"
            });
        }
    },
    effects: {
        *getNews({payload,success,error}, {dispatch, select, history}) {
            const {code,result,msg}=yield fetchNews(4);
            if(result)
            {
                yield dispatch({
                    type:"updateNews",
                    payload:result
                })
            }
        }

    },
    reducers: {
        updateNews(state, {payload}) {
            state.news=[...new Set(payload)];
            return {...state};
        }
    }
}
