import _ from "./_";
import $ from "jquery";
export function stopPropagation(e,onlyDefault) {
  if(onlyDefault)
  {
    e.preventDefault();
    return;
  }
  e.stopPropagation();
  e.preventDefault();
  e.nativeEvent.stopPropagation();
  if (e.nativeEvent.stopImmediatePropagation) {
    e.nativeEvent.stopImmediatePropagation();
  }
}
export function noop(){}
export function getRowSelect(selectedRowKeys,record,key="id")
{
  if(_.contains(selectedRowKeys,record[key])){
    selectedRowKeys = selectedRowKeys.filter((il)=>{
      return il != record[key];
    });
  }else{
    selectedRowKeys = [...selectedRowKeys,record.id];
  }
  return selectedRowKeys;
}
export function dispatchEvent(name,data)
{
    name=`dmworks_event_${name}`;
    $(document.body).trigger(name,data);
}
export function addListener(name,callback)
{
  name=`dmworks_event_${name}`;
  $(document.body).on(name,callback);
}