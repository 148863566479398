import React, {Component} from "react";
import {NavBar, Icon, Popover, Modal, Button} from "antd-mobile"
import PropTypes from "prop-types";
import _ from "../../utils/_";
import bizData from "./bizData";
import {parseParam} from "../../utils/Match";
import IconX from "../../components/IconX";
import ShareTo from "../../components/ShareTo";
import ToCoop from "../../components/ToCoop";
export default class BizDetail extends Component
{
    static get contextTypes() {
        return {
            history: PropTypes.object
        }
    }
    getLabel()
    {
        let {action,id}=parseParam("/view/biz",this.context.history.location.pathname);
        if(action=="detail"&&id)
        {
            let find=bizData.filter((itemData)=>{
                return itemData.id==id
            });
            if(find&&find.length)
            {
                return find[0].label;
            }
        }
        // let find=bizData.filter(({id})=>{
        //
        // })
    }
    getContent()
    {
        let {action,id}=parseParam("/view/biz",this.context.history.location.pathname);
        if(action=="detail"&&id)
        {
            let find=bizData.filter((itemData)=>{
                return itemData.id==id
            });
            if(find&&find.length)
            {
                return find[0].content();
            }
        }
    }
    state={
        visible:false,
        show:false
    };
    render()
    {
        return <div  className="">
            <NavBar
                className="biz_detail_nav"
                mode="light"
                icon={<Icon type="left" />}
                onLeftClick={() => {
                    this.context.history.push("/view/biz");
                }
                }
                rightContent={[
                    <Popover visible={this.state.visible} onVisibleChange={(visible)=>{
                        this.setState({visible})
                    }} mask overlay={
                        [<Popover.Item key="share">
                            <ShareTo >
                                <div class="flex_center" onClick={()=>{
                                    this.setState({visible:false})
                                }}><IconX type="share" size={20}/>
                                    <span className="share">分享到</span></div>
                            </ShareTo>
                        </Popover.Item>,

                            ]
                    }><Icon key="1" type="ellipsis" /></Popover>
                ]}
            >{this.getLabel()}</NavBar>
            <div className="detail_content_card">
                {this.getContent()}
            </div>
            <Button type="ghost" className="home_biz_more" onClick={()=>{
                this.context.history.push("/view/biz");
            }
            } >查看更多业务</Button>

        </div>
    }
}